import axios from 'axios';
import CFG from '../config';

export function get(mod, filter) {
   return new Promise((res, rej) => {
      let url = CFG.api_url +`/mod/${mod}?`;
      for(let i in filter) {
         url += `&${i}=${filter[i]}`;
      }
      axios.get(url).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('get user api err:', e);
         rej(e);
      })
   });
}
export function create() {

}

export function edit() {

}