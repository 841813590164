<template>
   <v-dialog v-model="show" max-width="720">
      <v-card>
         <v-card-title class="label-color">{{uid=='new'? 'User Baru':'Edit User'}}</v-card-title>
         <v-divider class="mx-4"></v-divider>
         <v-card-text>
            <v-form ref="form">
               <v-row>
                  <v-col cols="7">
                     <v-text-field label="Nama" v-model="fm.nama" :rules="[v => !!v || 'Nama harus diisi']" />
                     <v-text-field label="Username" v-model="fm.user" :disabled="uid!='new'" :rules="[v => !!v || 'Username harus diisi']" />
                     <v-switch label="Disabled" v-model="fm.dis" :false-value="0" :true-value="1" :disabled="limited"/>
                     <v-select label="Group1" v-model="fm.grp_id" :items="all_grp" :rules="[v => !!v || 'Group1 harus diisi']" :disabled="limited"/>
                     <v-select label="Group2" v-model="fm.s_grp" :items="all_grp" :disabled="limited"/>
                     <v-select label="Branch" v-model="fm.branch_id" :items="all_cabang" :rules="[v => !!v || 'Branch harus diisi']" :disabled="limited"/>
                  </v-col>
                  <v-col cols="5">
                     <img-input label="Avatar" field="avatar" input-label="upload image" :url="fm.avatar" v-on:file="$set(fm,'avatar_img',arguments[0])" />
                     <img-input label="Sign" field="sign" input-label="upload image" :url="fm.sign" v-on:file="$set(fm,'sign_img',arguments[0])" />
                  </v-col>
               </v-row>
               <div class="d-flex">
                  <div class="label-color">hak akses klien</div>
                  <v-spacer />
                  <v-btn x-small text color="orange" @click="selectAll" :disabled="limited">pilih semua</v-btn> |
                  <v-btn x-small text color="indigo" @click="clearAll" :disabled="limited">bersihkan semua</v-btn>
               </div>
               <div class="d-flex flex-wrap client-box">
                  <v-chip
                     class="cln-item"
                     v-for="(c,i) in all_client"
                     :key="i"
                     small
                     dark
                     :color="(allowed_client.indexOf(c.id+'') > -1) ? '#7af':'#ccc'"
                     @click="selectClient(c.id+'')"
                  >{{c.label}}</v-chip>
               </div>
            </v-form>
         </v-card-text>
         <v-divider else class="mx-4"></v-divider>
         <v-card-actions>
            <v-spacer />
            <v-btn color="#aaa" outlined rounded @click="$emit('close');">close</v-btn>
            <v-btn color="primary" :loading="on_submit" outlined rounded @click="save">save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import {get as get_model} from '../api/list';
import {detail, create, edit} from '../api/user';
import imgInput from '../components/img-input';

export default {
   props: ['uid'],
   components: {imgInput},
   data: () => ({
      fm: {},
      all_client: [],
      allowed_client: [],
      all_grp: [],
      all_cabang: [],
      on_submit: false,
   }),
   computed:{
      show: {
         set(val) {
            if(!val) this.$emit('close');
         },
         get() {
            return !!this.uid;
         }
      },
      limited() {
         return this.$store.state.auth.user && this.$store.state.auth.user.grp_id !== 1
      }
   },
   mounted() {
      get_model('roles').then(rsl => { this.all_grp = rsl.map(x => ({text: x.grp, value: x.id})) });
      get_model('branch').then(rsl => { this.all_cabang = rsl.map(x => ({text: x.branch, value: x.id})) });
      get_model('client').then(rsl => { this.all_client = rsl; });
   },
   watch: {
      uid(val) {
         if(val && val !== 'new') {
            detail(val).then(rsl => { this.fm = rsl.data });
         } else {
            this.$refs.form.reset();
         }
      }
   },
   methods: {
      selectAll() {
         this.allowed_client = this.all_client.map(x => x.id+'');
      },
      selectClient(val) {
         const idx = this.allowed_client.indexOf(val);
         if(idx == -1) {
            this.allowed_client.push(val);
         } else this.allowed_client.splice(idx, 1);
      },
      clearAll() {
         this.allowed_client = [];
      },
      save() {
         if(!this.$refs.form.validate()) return false;
         const {id, ...data} = this.fm;
         this.on_submit = true;

         if(this.allowed_client.length > 0) {
            data.client = this.allowed_client.join(',');
         } else data.client = null;

         const fd = new FormData();
         for(let i in data) fd.append(i, data[i] === undefined ? '' : data[i]);

         (this.uid == 'new' ? create(fd) : edit(id, fd)).then(rsl => {
            if(rsl.stat == 1) {
               this.$store.dispatch('alert', { title: 'Success', text: rsl.mess, type: 'success', showConfirmButton: false, timer: 3000})
               .then(() => {
                  this.$emit('close', true);
               });
            } else this.$store.dispatch('alert', {title: 'Ooops!', text: rsl.mess, type: 'warning'});
         }).finally(() => { this.on_submit = false });
      },
   }
}
</script>
<style scoped>
.cln-item {
   margin: 5px 0 0 8px;
}
.v-card__actions > .v-btn.v-btn { width: 108px; }
.label-color { color: #4b8; }
.client-box {max-height: 300px; overflow: auto;}
</style>